.App{
	text-align: center;
}

.App-logo{
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header{
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link{
	color: #61dafb;
}

@keyframes App-logo-spin{
	from{
		transform: rotate(0deg);
	}
	to{
		transform: rotate(360deg);
	}
}

.react-phone-number-input__row{
	/* This is done to stretch the contents of this component */
	display: flex;
	align-items: center;
}

.react-phone-number-input__icon{
	/* The flag icon size is 4x3 hence the exact `width` and `height` values */
	width: calc(1.24em + 1px);
	height: calc(0.93em + 1px);
	padding-bottom: 5px;
	/* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
	border: none;

	/* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
	box-sizing: content-box;
}

.react-phone-number-input__icon--international{
	/* The international icon size is square */
	/* and also has no border hence the `2 * 1px` `width` and `height` compensation */
	width: calc(0.93em + 2px);
	height: calc(0.93em + 2px);

	/* The international icon size is square hence the exact `padding` value */
	/* for precise alignment with `4x3` sized country flags. */
	padding-left: 0.155em;
	padding-right: 0.155em;

	border: none;
}

.react-phone-number-input__icon-image{
	max-width: 100%;
	max-height: 100%;

}

.react-phone-number-input__ext-input{
	width: 3em;
}

.react-phone-number-input__ext{
	white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input{
	margin-left: 0.5em;
}

.react-phone-number-input__country--native{
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: 0.5em;
}

.react-phone-number-input__country-select{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.react-phone-number-input__country-select-arrow{
	display: block;
	content: '';
	width: 0;
	height: 0;
	margin-bottom: 0.1em;
	margin-top: 0.3em;
	margin-left: 0.3em;
	border-width: 0.35em 0.2em 0 0.2em;
	border-style: solid;
	border-left-color: transparent;
	border-right-color: transparent;
	color: #B8BDC4;
	opacity: 0.7;
	transition: color 0.1s;
}

.react-phone-number-input__country-select-divider{
	font-size: 1px;
	background: black;
}

.react-phone-number-input__input{
	height: 100%;
	width: 100%;
	outline: none;
	border-radius: 0;
	padding: 0;
	appearance: none;
	border: none;
	transition: border 0.1s;
	font-size: inherit;
}