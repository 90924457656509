
.login-container{
	padding: 35px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
	-webkit-appearance: none;
	margin: 0;
}
